<label for="Subject">Ämne</label>
<ejs-dropdownlist
  id="Subject"
  name="Subject"
  class="e-dropdownlist e-field"
  [dataSource]="currentSubjects"
  [fields]="subjectFields"
  [(value)]="currentSubjectId"
  (change)="onSubjectChange($event)"
  [enabled]="!disabled"
></ejs-dropdownlist>
